import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';

const ScanPage = () => {
  const [name, setName] = useState('');
  const [time, setTime] = useState('');
  const navigate = useNavigate();

  const handleTimeClick = (time) => {
    setTime(time);
  };

  const handleSubmit = async () => {
    await fetch('http://185.8.129.156:9091/appointment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, time }),
    });
    navigate('/');
  };

  const generateTimeSlots = () => {
    const slots = [];
    const start = new Date();
    start.setSeconds(0, 0); // Round down to the nearest minute

    // Round up to the nearest 10-minute mark
    if (start.getMinutes() % 10 !== 0) {
      start.setMinutes(start.getMinutes() + (10 - start.getMinutes() % 10));
    }

    for (let i = 0; i < 6; i++) {
      const slotTime = new Date(start.getTime() + i * 10 * 60000);
      const hours = slotTime.getHours().toString().padStart(2, '0');
      const minutes = slotTime.getMinutes().toString().padStart(2, '0');
      slots.push(`${hours}:${minutes}`);
    }
    return slots;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Typography variant="h4" gutterBottom>
        Randevu Bilgilerini Girin
      </Typography>
      <TextField
        label="Adınız"
        value={name}
        onChange={(e) => setName(e.target.value)}
        margin="normal"
      />
      <Typography variant="h6" gutterBottom>
        Randevu Saati Seçin
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {generateTimeSlots().map((slot) => (
          <Grid item key={slot}>
            <Button variant="contained" onClick={() => handleTimeClick(slot)}>
              {slot}
            </Button>
          </Grid>
        ))}
      </Grid>
      {time && (
        <Typography variant="h6" gutterBottom>
          Seçilen Zaman: {time}
        </Typography>
      )}
      <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: '20px' }}>
        Randevu Ayarla
      </Button>
    </Box>
  );
};

export default ScanPage;
