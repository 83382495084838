import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { Box, Grid, Typography, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScanPage from './ScanPage'; // Import the scan page component
import logo from '../assets/logo.png'; // Adjust the path to your image file
import '../styles.css';

const QRCodeGenerator = () => {
  const [url, setUrl] = useState('');
  const [appointment, setAppointment] = useState({ name: '', time: '' });
  const [scanned, setScanned] = useState(false);
  const [blink, setBlink] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    generateAndSetUrl();
    const ws = new WebSocket('ws://185.8.129.156:9090');
    ws.onmessage = (message) => {
      const data = JSON.parse(message.data);
      if (data.identifier) {
        generateAndSetUrl();
        setScanned(true);
      } else if (data.name && data.time) {
        setAppointment({ name: data.name, time: data.time });
        setScanned(false);
        const appointmentTime = new Date();
        const [hours, minutes] = data.time.split(':');
        appointmentTime.setHours(hours, minutes, 0, 0);
        const now = new Date();
        const timeout = appointmentTime.getTime() - now.getTime();
        if (timeout > 0) {
          setTimeout(() => {
            setBlink(true);
            setOpen(true);
            setTimeout(() => {
              setBlink(false);
              setOpen(false);
            }, 10000); // Stop blinking after 10 seconds and close popup
          }, timeout);
        }
      }
    };
    return () => ws.close();
  }, []);

  const generateAndSetUrl = () => {
    const baseUrl = 'https://tarikrandevu.disargames.com/#scan';
    setUrl(`${baseUrl}`);
    setScanned(false);
  };

  return (
<>
     
       
<Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '100vh' }}
          >
            <Typography variant="h4" gutterBottom>
              Tarık Randevu Sistemi
            </Typography>
            
            {url && (
              <Box mt={4} textAlign="center">
                <Typography variant="body1" gutterBottom>
                  Lütfen Randevu Almadan İletişim Kurmayın
                </Typography>
                <QRCode
                  value={url}
                  size={256}
                  fgColor="#FDCB0A" // QR kodun rengini değiştirin
                  bgColor="#000000" // Arka plan rengini değiştirin
                  level="H" // Hata düzeltme seviyesini ayarlayın
                  includeMargin={true} // QR kod etrafında margin bırakın
                  renderAs="svg" // SVG olarak render edin
                  imageSettings={{
                    src: logo, // Use the imported logo image
                    x: null,
                    y: null,
                    height: 64,
                    width: 64,
                    excavate: true, // QR kodun merkezinde görüntü için alan açın
                  }}
                />
                {scanned && (
                  <Box mt={2}>
                    <Typography variant="h6" color="error">
                      Randevu Alan Var
                    </Typography>
                  </Box>
                )}
                {appointment.name && (
                  <Box mt={2}>
                    <Typography variant="h6">
                      Randevu: {appointment.name} - {appointment.time}
                    </Typography>
                  </Box>
                )}
                {blink && (
                  <Box mt={2} className="blinking">
                    <Typography variant="h4" color="secondary">
                      {appointment.name}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Grid>
     
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Randevu Zamanı Geldi!</DialogTitle>
        <DialogContent>
          <Typography variant="h5">{appointment.name}</Typography>
        </DialogContent>
      </Dialog>
      </>
  );
};

export default QRCodeGenerator;
