/*!
=========================================================
* Whomii Dashboard React - v2.1.0
=========================================================

* Product Page: https://github.com/WhoMiiApp/disc-dashboard
* Copyright 2024 Whomii (https://www.whomii.me)
* Licensed under MIT (https://github.com/WhoMiiApp/disc-dashboard/blob/main/LICENSE)

* Coded by Whomii

*/

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Route, Routes, Navigate, useNavigate } from "react-router-dom";

import QRCodeGenerator from "./components/QRCodeGenerator.jsx";
import ScanPage from "./components/ScanPage.jsx";

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<QRCodeGenerator />} />
      <Route path="/scan" element={<ScanPage />} />
      <Route path="*" element={<ScanPage />} />
    </Routes>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HashRouter>
    <App />
  </HashRouter>
);

document.body.style.backgroundColor = "white";
